import React, { useEffect, useState } from 'react'
import Modal from '@components/common/modal'
import { Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { Download, X } from 'react-feather'
import { useRouter } from 'next/router'
import { formatLocale } from '@lib/utils/i18n'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  grades: number[] | string[]
  curriculumPDFPrefix?: string
}

const CURRICULUM_PDF_PATH = '/pdfs/curriculum/v10'

const languagePrefixMap = {
  'ja-JP': 'ja',
}

const CurriculumDownloadModal = ({
  isModalOpen,
  setIsModalOpen,
  grades,
  curriculumPDFPrefix = 'cp',
}: Props) => {
  const [coursePrefix, setCoursePrefix] = useState<string | null>(null)
  const [languagePrefix, setLanguagePrefix] = useState<string | null>(null)

  const router = useRouter()

  useEffect(() => {
    const locale = formatLocale(router?.locale)
    // Temp hardcode cgm for ja-JP locale
    const updatedCoursePrefix = locale === 'ja-JP' ? 'cgm' : curriculumPDFPrefix
    const updatedLanguagePrefix = languagePrefixMap[locale] || ''

    setCoursePrefix(updatedCoursePrefix)
    setLanguagePrefix(updatedLanguagePrefix)
  }, [router?.locale, curriculumPDFPrefix])

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      width="w-full lg:max-w-xl"
    >
      <div className="py-2">
        <button
          className="absolute top-0 right-0 mt-3 mr-3 text-lg cursor-pointer text-grey-800 font-700"
          onClick={() => setIsModalOpen(false)}
        >
          <X className="stroke-2 text-grey-800" />
        </button>
        <div className="flex flex-col space-y-10 text-center">
          <h3 className="text-2xl font-700 text-grey">
            <Trans>Download curriculum</Trans>
          </h3>
          <div className="flex justify-center flex-wrap">
            {grades.map((grade) => (
              <a
                href={`https://codingal.s3.ap-south-1.amazonaws.com/web${CURRICULUM_PDF_PATH}/${
                  languagePrefix ? `${languagePrefix}-` : ``
                }${
                  coursePrefix ? `${coursePrefix}-` : ''
                }grade-${grade}-curriculum.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                key={grade}
                className="w-32 m-2 flex justify-center items-center px-1 py-2 space-x-2 rounded-md border-2 cursor-pointer lg:py-2 lg:px-2 font-600 text-orange border-grey-500 hover:border-orange hover:bg-orange-200"
              >
                <span className="text-base lg:text-lg">
                  <Trans>Grade</Trans> {i18n.number(grade)}
                </span>
                <Download size={14} strokeWidth={3} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CurriculumDownloadModal
